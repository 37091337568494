<template>
  <div class="page-listings">
    <listings-tour v-if="$store.state.tour & !loading" />
    <transition name="menu-popover" v-if="!loading">
      <div v-if="pdfPreview" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <embed
          width="90%"
          height="100%"
          title="If you are having issues viewing this PDF, your browser security settings are set to download PDFs instead of viewing in the browser. You can also try using Chrome instead of Edge."
          :src="'data:application/pdf;base64,' + this.pdfPreview"
          style="background: #fff"
        />
      </div>
    </transition>
    <section
      v-if="loading == true"
      class="py-5 bg9 bs-shadow mobile-top"
      style="min-height: 260px"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">Loading Order Listings....</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
        <!--
        <h2 class="text-white lh1"></h2>
        <p class="text-white lh1">Loading details</p>
        -->
      </div>
    </section>

    <section v-else class="pt-5 pb-3 bg9 bs-shadow mobile-top">
      <div class="container">
<!--
          <div class="new-search mb20">
          <div class="input-group">
            <input 
            v-model="searchValue" 
            @keyup.enter.prevent="searchOrder"
            type="text" 
            class="form-control" 
            aria-label="Search listings by order number, PO number, or order name.." 
            style="padding: 14px"
            placeholder="Search listings by order number, PO number, or order name..."
            >
            <div class="input-group-append">
              <span class="input-group-text new-search-btn2">
                <span class="material-icons">find_replace</span>
                {{ " " }} Clear
              </span>

              <span @click="searchOrder" class="input-group-text new-search-btn1">
                <span class="material-icons">search</span>
                {{ " " }}Search
              </span>

            </div>
          </div>
        </div>
        -->
        <input
          id="listings-step-1"
          class="top-search bs-large mt0"
          type="text"
          placeholder="Search listings by order number, PO number, or order name..."
          v-model="searchValue"
          @keyup.enter.prevent="searchQuery"
        />
        <div class="search-btns" style="display: flex; margin-bottom: 0">
          <div class="left-side" style="margin-right: 10px">
            <div id="listings-step-2" class="ac-select" style="margin-right: 0">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="acFilter"
              >
                <option
                  :value="null"
                  selected
                  class="all-contractors"
                  id="firstSelector"
                  style="text-transform: capitalize !important"
                >
                  Area Contractor
                </option>
                <option
                  v-for="ac in acs"
                  :key="ac.acid"
                  :value="ac.acid"
                  id="text"
                >
                  {{ " " + ac.acName + " (" + ac.acid + ")" }}
                </option>
                <!--
				>{{ " " + ac.acName + " (" + ac.acid + ")" }}</option>
                -->
              </select>
            </div>
            <div class="dates" id="dates" style="display: flex">
              <datepicker
                format="MMM dd yyyy"
                placeholder="From Date"
                v-model="startDate"
                wrapperClass="pickthedate"
                :typeable="true"
              ></datepicker>

              <datepicker
                id="listings-step-3"
                format="MMM dd yyyy"
                placeholder="To Date"
                v-model="endDate"
                wrapperClass="pickthedate"
                style="margin-right: 0"
                :typeable="true"
              ></datepicker>
            </div>

            <div class="radios">
              <div class="control-group">
                <label class="control control--radio">
                  Entered
                  <input
                    type="radio"
                    name="radio"
                    checked="checked"
                    v-model="promised"
                    value="false"
                  />
                  <div class="control__indicator"></div>
                </label>
                <label class="control control--radio">
                  Shipping
                  <input
                    type="radio"
                    name="radio"
                    v-model="promised"
                    value="true"
                  />
                  <div class="control__indicator"></div>
                </label>
                <!--
                <label id="EO-step-3" class="control control--radio">
                  Completed
                  <input
                    type="radio"
                    name="radio"
                    v-model="picked"
                    value="completed"
                  />
                  <div class="control__indicator"></div>
                </label>
                -->
              </div>
            </div>
          </div>

          <div class="right-side" style="display: flex">
            <div
              class="search-btns"
              style="display: flex; margin-top: 0; margin-left: auto"
            >
              <button
                v-if="searchValue || startDate || endDate || acFilter"
                @click.prevent="searchQuery"
                class="search"
              >
                <span class="material-icons">search</span>
                {{ " " }} Search
              </button>
              <button v-else class="search" disabled>
                <span class="material-icons">search</span>
                {{ " " }} Search
              </button>

              <button
                v-if="searchValue || startDate || endDate || acFilter"
                @click="clearSearch"
                class="clear"
              >
                <span class="material-icons">find_replace</span>
                {{ " " }} Clear
              </button>
              <button v-else disabled class="clear">
                <span class="material-icons">find_replace</span>
                {{ " " }} Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <div>
            <div>
              <div
                v-if="loading == true"
                class="col-xs-12 col-sm-12 col-12 col-lg-12"
              >
                <div class="flex-box-wrap">
                  <div class="flex-internal">
                    <atom-spinner
                      :animation-duration="1000"
                      :size="40"
                      :color="'#575757'"
                    />
                    <p>Loading......</p>
                  </div>
                </div>
              </div>

              <div
                v-else-if="loading == false && !filteredListings.length"
                class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
              >
                <div>
                  <h2
                    style="margin: 30px 20px; color: #429da8; font-weight: 400"
                  >
                    It looks like there are no recent orders to show...
                  </h2>
                  <h3
                    style="margin: 30px 20px; color: #429da8; font-weight: 400"
                  ></h3>
                  <img src="@/assets/img/noData.svg" style="width: 100%" />
                </div>
              </div>

              <div v-else class="page" :key="searchRender">



                <div
                  class="
                    listing-header
                    d-flex
                    align-items-center
                    p-3
                    mt10
                    mb10
                    rounded
                    b-shadow
                  "
                >
                  <div class="pag-wrapper">
                    <p class="mrauto">
                      <small>Page </small>{{ currPage }} <small>of</small>
                      {{ Math.ceil(this.filteredListings.length / 100) }}
                    </p>
                    <div id="listings-step-4" class="nav-btns mlauto">
                      <button v-if="this.currPage === 1" disabled>
                        <i class="material-icons">keyboard_arrow_left</i> Back
                      </button>

                      <button v-else @click.prevent="setPage(currPage - 1)">
                        <i class="material-icons">keyboard_arrow_left</i> Back
                      </button>

                      <button
                        v-if="
                          this.currPage === this.totalPage ||
                          this.currPage ==
                            Math.ceil(this.filteredListings.length / 100)
                        "
                        disabled
                      >
                        Next
                        <i class="material-icons">keyboard_arrow_right</i>
                      </button>

                      <button v-else @click.prevent="setPage(currPage + 1)">
                        Next
                        <i class="material-icons">keyboard_arrow_right</i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="listing">
                  <div v-if="$store.getters.isACKSnINVS" class="tab-heading">
                    <router-link
                      to="/listings"
                      class="order-tab tab-head active-tab"
                      ><h5 class="mb0" data-config-id="header" style="margin-top:0!important">
                        Orders
                      </h5></router-link
                    >

                    <router-link
                      to="/acknowledgments"
                      class="ack-tab tab-head inactive-tab"
                      ><h5 class="mb0" data-config-id="header" style="margin-top:0!important">
                        Acknowledgments
                      </h5></router-link
                    >

                    <router-link
                      to="/invoices"
                      class="inv-tab tab-head inactive-tab"
                      ><h5 class="mb0" data-config-id="header" style="margin-top:0!important">
                        Invoices
                      </h5></router-link
                    >
                  </div>

                  <div v-else>
                    <h2 class="mb0" data-config-id="header">Order status</h2>
                    <p>
                      <small class="text-muted"
                        >Highlighted orders have new invoices or acknowledgments
                        that have been posted since your last login.</small
                      >
                    </p>
                  </div>

                  <div class="table-responsive">



                    
                    <table
                      class="table-striped table-bordered"
                      style="display: table"
                    >
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            class="table-link ten"
                            @click="sortCol('status')"
                            :class="
                              columnSort == 'status' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Status</span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'status'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>
                          <th
                            scope="col"
                            class="table-link ten"
                            @click="sortCol('orderNo')"
                            :class="
                              columnSort == 'orderNo' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Order</span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'orderNo'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>
                          <th
                            scope="col"
                            class="table-link twenty"
                            @click="sortCol('customer_PO_No')"
                            :class="
                              columnSort == 'customer_PO_No'
                                ? 'column-active'
                                : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">PO # </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'customer_PO_No'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>
                          <th
                            scope="col"
                            class="table-link thirty"
                            @click="sortCol('projName')"
                            :class="
                              columnSort == 'projName' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Name </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'projName'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>

                          <th
                            scope="col"
                            class="table-link ten"
                            @click="sortCol('entered_Date')"
                            :class="
                              columnSort == 'entered_Date'
                                ? 'column-active'
                                : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Entered </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'entered_Date'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>

                          <th
                            scope="col"
                            class="table-link ten"
                            @click="dateCol('estimated_Date')"
                            :class="
                              columnSort == 'estimated_Date'
                                ? 'column-active'
                                : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Estimated </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'estimated_Date'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>

                          <th
                            scope="col"
                            class="table-link ten"
                            @click="dateCol('promised_Date')"
                            :class="
                              columnSort == 'promised_Date'
                                ? 'column-active'
                                : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Promised </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'promised_Date'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>

                          <th
                            scope="col"
                            class="table-link ten"
                            @click="sortCol('order_Total')"
                            :class="
                              columnSort == 'order_Total' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Total </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'order_Total'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>

                          <th
                            scope="col"
                            class="ten"
                            @click="sortCol('inv')"
                            :class="
                              columnSort == 'order_Total' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Inv </span>
                            </span>
                          </th>

                          <th scope="col" class="ten">
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Ack </span>
                            </span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                     
                        <tr
                          scope="row"
                          v-bind:key="index"
                          v-for="(listing, index) in filteredListings.slice(
                            pageStart,
                            pageStart + countOfPage
                          )"
                          :class="{
                            new:
                              (listing.confirmationPDF.length &&
                                moment(
                                  listing.confirmationPDF[0].pdfdate
                                ).isAfter(
                                  moment(
                                    $store.state.user.LastActivity
                                  ).subtract(1, 'd')
                                )) ||
                              (listing.invoicePdf.length &&
                                moment(listing.invoicePdf[0].pdfdate).isAfter(
                                  moment(
                                    $store.state.user.LastActivity
                                  ).subtract(1, 'd')
                                )),
                          }"
                        >
                          <td>
                            <span>{{ listing.status }}</span>
                          </td>
                          <td>
                            <router-link
                              @click="loadTop"
                              :id="index === 0 ? 'listings-step-5' : ''"
                              :to="/order-details/ + listing.orderNo"
                              >{{ listing.orderNo }}</router-link
                            >
                          </td>
                          <td>{{ listing.customer_PO_No }}</td>
                          <td
                            v-if="listing.projName"
                            v-html="listing.projName"
                          ></td>
                          <td v-else>Unavailable</td>

                          <td>
                            {{
                              moment(listing.entered_Date).format("MM-DD-YYYY")
                            }}
                          </td>

                          <td v-if="listing.estimated_Date">
                            {{
                              moment(listing.estimated_Date).format(
                                "MM-DD-YYYY"
                              )
                            }}
                          </td>
                          <td v-else></td>
                          <td v-if="listing.promised_Date">
                            {{
                              moment(listing.promised_Date).format("MM-DD-YYYY")
                            }}
                          </td>
                          <td v-else></td>

                          <td>{{ listing.order_Total | currency }}</td>

                          <td>
                            <span
                              v-if="listing.invoicePdf.length"
                              @click="getPDF(listing.invoicePdf[0].blobPath)"
                              class="material-icons document-link"
                              >description</span
                            >
                          </td>
                          <td>
                            <span
                              v-if="listing.confirmationPDF.length"
                              @click="
                                getPDF(listing.confirmationPDF[0].blobPath)
                              "
                              class="material-icons document-link"
                              >description</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- card -->
                <div
                  class="
                    listing-header
                    d-flex
                    align-items-center
                    p-3
                    mt10
                    mb-3
                    rounded
                    b-shadow
                  "
                >
                  <div class="pag-wrapper">
                    <p class="mrauto">
                      <small>Page</small> {{ currPage }} <small>of</small>
                      {{ Math.ceil(this.filteredListings.length / 100) }}
                    </p>
                    <div class="nav-btns mlauto">
                      <button v-if="this.currPage === 1" disabled>
                        <i class="material-icons">keyboard_arrow_left</i> Back
                      </button>

                      <button v-else @click.prevent="setPage(currPage - 1)">
                        <i class="material-icons">keyboard_arrow_left</i> Back
                      </button>

                      <button
                        v-if="
                          this.currPage === this.totalPage ||
                          this.currPage ==
                            Math.ceil(this.filteredListings.length / 100)
                        "
                        disabled
                      >
                        Next
                        <i class="material-icons">keyboard_arrow_right</i>
                      </button>

                      <button v-else @click.prevent="setPage(currPage + 1)">
                        Next
                        <i class="material-icons">keyboard_arrow_right</i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- page -->
            </div>
          </div>
        </div>
        <!-- end other column -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import { HollowDotsSpinner } from "epic-spinners";
import Avatar from "vue-avatar";
import Datepicker from "vuejs-datepicker";
import ListingsTour from "../components/Tours/ListingsTour";
import toUnicode from "../_helpers/toUnicode";

const auth = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
  },
};

export default {
  name: "Listings",
  components: {
    AtomSpinner,
    Avatar,
    Datepicker,
    ListingsTour,
  },
  data() {
    return {
      attributes: [
        {
          key: "today",
          highlight: true,
          dot: "red",
          dates: {
            start: null, // From the beginning of time
            end: new Date(), // Until today
          },
        },
      ],
      ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
      backend: process.env.VUE_APP_SERVER_URL,
      searchValue: this.$route.query.search ? this.$route.query.search : "",
      search: "",
      storedListings: [],
      searchRender: false,
      startDate: this.$route.query.start ? this.$route.query.start : null,
      endDate: this.$route.query.end ? this.$route.query.end : null,
      searchStarted: false,
      promised: false,
      acs: [],
      listings: [],
      acFilter: this.$route.query.ac ? this.$route.query.ac : null,
      selectedCat: "Show All",
      searching: false,
      loading: true,
      countOfPage: 100,
      currPage: 1,
      noListingFound: false,
      colSort: false,
      columnSort: "",
      pdfPreview: "",
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
      view: "orders",
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.searchStarted = false;
      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {

      this.acListSync();

      if (
        this.searchValue.length ||
        this.acFilter != null ||
        this.startDate != null ||
        this.endDate != null
      ) {
        this.searchQuery();
      } else {
        this.listingSync();
      }
    },

    listingSync: function () {
      this.loading = true;

      if (!this.$store.state.listings.listings.length) {
        //console.log("getting listings");
        return axios
          .get(`${this.ordermanageapi}/order/`, auth)
          .then((response) => {
            //console.log(response);

            //fix null estinated dates so sorting will work
            response.data.forEach(function (o) {
              if (o.estimated_Date === null) {
                o.estimated_Date = "";
              }
            });
            this.listings = response.data;
            this.$store.commit("UPDATE_LISTINGS", this.listings);
            this.loading = false;         
          });
      } else {
        //console.log("vuex has length");
        this.listings = this.$store.state.listings.listings;
        this.loading = false;
      }
    },

    acListSync: function () {
      this.loading = true;

      if (!this.$store.state.acList.acList.length) {
        //console.log("getting acList");
        return axios
          .get(`${this.ordermanageapi}/aclist?active=true`, auth)
          .then((response) => {
            //console.log(response);
            const data = response.data;

            /*

            const low = data.filter(function (e) {
              return e.acid < 210;
            });

            const mid = data.filter(function (e) {
              return e.acid == 80069;
            });

            const high = data.filter(function (e) {
              return e.acid > 800000 && e.acid < 800250;
            });

            const all = low.concat(high).concat(mid);
            const alph = all.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            */

            //this.acs = alph;
            const alph = data.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            this.acs = alph;
            //this.acs.sort((a, b) => (a.acName > b.acName) ? 1 : -1);
            //this.loading = false;
            this.$store.commit("UPDATE_ACLIST", this.acs);
          });
      } else {
        //console.log("vuex has length");
        this.acs = this.$store.state.acList.acList;
        this.loading = false;
      }
    },

    toUpperCase: function (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.toUpperCase();
      });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    searchReset: function () {
      this.selectedCat = "Show All";
      this.acFilter = 0;
    },

    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },

    catSwitcher: function (catType) {
      //console.log(catType);
      let category = catType;
      //console.log(category);
      if (category !== "Show All") {
        this.selectedCat = category;
        this.search = "";
        //console.log(category);
      } else {
        this.selectedCat = "Show All";
      }
    },
    searchQuery(event) {
      this.searchStarted = true;
      if (this.searchStarted === true) {
        this.storedListings = this.listings;
      }

      //console.log(toUnicode(this.searchValue));

      this.loading = true;
      this.searching = true;
      this.search = "";

      if (
        this.searchValue.length &&
        this.acFilter != null &&
        this.startDate != null &&
        this.endDate != null
      ) {
        //all four
        history.pushState(
          {},
          "",
          `/listings?search=${toUnicode(this.searchValue.trim())}&ac=${this.acFilter}&start=${this.startDate}&end=${this.endDate}&promised=${this.promised}`
        );
      } else if (
        this.searchValue.length &&
        this.acFilter != null &&
        this.startDate != null
      ) {
        //search, ac, and start
        history.pushState(
          {},
          "",
          `/listings?search=${toUnicode(this.searchValue.trim())}&ac=${this.acFilter}&start=${this.startDate}&promised=${this.promised}`
        );
      } else if (
        this.searchValue.length &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        //search, ac, and end
        history.pushState(
          {},
          "",
          `/listings?search=${toUnicode(this.searchValue.trim())}&ac=${this.acFilter}&end=${this.endDate}&promised=${this.promised}`
        );
      } else if (this.searchValue.length && this.acFilter != null) {
        //search and AC
        history.pushState(
          {},
          "",
          `/listings?search=${toUnicode(this.searchValue.trim())}&ac=${this.acFilter}&promised=${this.promised}`
        );
      } else if (this.searchValue.length && this.endDate != null) {
        //search, and end
        history.pushState(
          {},
          "",
          `/listings?search=${toUnicode(this.searchValue.trim())}&end=${this.endDate}&promised=${this.promised}`
        );
      } else if (this.searchValue.length && this.startDate != null) {
        //search and start
        history.pushState(
          {},
          "",
          `/listings?search=${toUnicode(this.searchValue.trim())}&start=${this.startDate}&promised=${this.promised}`
        );
      } else if (this.acFilter != null && this.endDate != null) {
        //ac and end
        history.pushState(
          {},
          "",
          `/listings?ac=${this.acFilter}&end=${this.endDate}&promised=${this.promised}`
        );
      } else if (this.acFilter != null && this.startDate != null) {
        //ac, and start
        history.pushState(
          {},
          "",
          `/listings?ac=${this.acFilter}&start=${this.startDate}&promised=${this.promised}`
        );
      } else if (this.searchValue.length) {
        history.pushState(
          {},
          "",
          `/listings?search=${toUnicode(this.searchValue.trim())}&promised=${this.promised}`
        );
      } else if (this.acFilter != null) {
        history.pushState(
          {},
          "",
          `/listings?ac=${this.acFilter}&promised=${this.promised}`
        );
      } else if (this.startDate != null) {
        history.pushState(
          {},
          "",
          `/listings?start=${this.startDate}&promised=${this.promised}`
        );
      } else if (this.endDate != null) {
        history.pushState(
          {},
          "",
          `/listings?end=${this.endDate}&promised=${this.promised}`
        );
      } else {
        history.pushState({}, "", `/listings`);
      }

      return axios.get(this.searchEndpointCompute, auth).then((response) => {
        this.listings = response.data;

        console.log(response);
        this.loading = false;
        this.searching = false;
      });
    },
    clearSearch() {
      this.searchValue = "";
      this.promised = "false";
      this.searchRender = !this.searchRender;
      this.startDate = null;
      this.acFilter = null;
      this.endDate = null;

      if (this.searchStarted == true) {
        //this.listings = this.storedListings;
        this.listingSync();
        this.searchStarted = false;
      }

      history.pushState({}, "", `/listings`);

      //this.listings = this.storedListings;

      this.$forceUpdate();

      //this.$forceUpdate();
      //Vue.set(this.listings, this.storedListings);
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    sortCol: function (type) {
      console.log(type);
      this.columnSort = type;
      const COLUMN = type;



        //console.log(COLUMN)
        /*
        const distantFuture = new Date(8640000000000000);
        const firstSort = type;

        let sorted = filteredListings.sort((a, b) => {
          let dateA = a[firstSort] ? new Date(a[firstSort]) : distantFuture;
          let dateB = b[firstSort] ? new Date(b[firstSort]) : distantFuture;
          return dateA.getTime() - dateB.getTime();
        });

        this.filteredListings = sorted;
        */


      if (this.colSort) {
        this.filteredListings.sort((a, b) => (a[COLUMN] > b[COLUMN] ? 1 : -1));
      } else {
        this.filteredListings.sort((a, b) => (a[COLUMN] < b[COLUMN] ? 1 : -1));
      }


      this.colSort = !this.colSort;
    },

    dateCol: function (type) {
      console.log(type);
      this.columnSort = type;
      const COLUMN = type;
      const distantFuture = new Date(8640000000000000);

      if (this.colSort) {
        this.filteredListings.sort((a, b) => {
          let dateA = a[COLUMN] ? new Date(a[COLUMN]) : distantFuture;
          let dateB = b[COLUMN] ? new Date(b[COLUMN]) : distantFuture;
          return dateA > dateB ? 1 : -1;
        });
      } else {
        this.filteredListings.sort((a, b) => {
          let dateA = a[COLUMN] ? new Date(a[COLUMN]) : distantFuture;
          let dateB = b[COLUMN] ? new Date(b[COLUMN]) : distantFuture;
          return dateA < dateB ? 1 : -1;
        });
      }
      this.colSort = !this.colSort;
    },

    loadTop() {
      window.scrollTo(0, 0);
    },
    calcProcessing: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate == "In Engineering" ||
          orderstate == "In Planning" ||
          orderstate == "Planned" ||
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          //console.log("true");
          // this.processing = true;
          return true;
        } else {
          // this.processing = false;
          //console.log("false");
          return false;
        }
      } else {
        return;
      }
    },
    calcPreProd: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate === "In Planning" ||
          orderstate == "Planned" ||
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },
    calcInProd: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },
    calcShipped: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate === "Partially Delivered" ||
          orderstate === "Delivered" ||
          orderstate === "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },
    getPDF(link) {
      return axios
        .get(`${this.ordermanageapi}/${link}`, auth)
        .then((response) => {
          console.log(response.data);
          this.pdfPreview = response.data[0].pdfblog;
        });
    },
    showPDF(doc) {
      this.pdfPreview = doc;
    },
    closeLightbox() {
      this.pdfPreview = "";
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredListings: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.listings.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.OrderNumber.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else if (this.selectedCat !== "Show All") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.listings.filter((f) => {
          return f.Type == this.selectedCat;
        });
      } else {
        return this.listings;
      }
    },

    foundNoListing: function () {
      if (this.search.length > 1 && this.filteredListings.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.listings.length / this.countOfPage);
    },
    listingRestore: function () {
      if (
        !this.searchValue &&
        !this.loading &&
        !this.searching &&
        this.storedListings.length >= this.listings
      ) {
        //console.log("fired off");
        this.listings = this.storedListings;
      }
      return;
    },
    searchEndpointCompute: function () {
      // has order number, no start, no end, no ac
      if (
        this.searchValue.length &&
        !this.startDate &&
        !this.endDate &&
        !this.acFilter
      ) {
        return `${this.ordermanageapi}/order?search=${toUnicode(this.searchValue.trim())}&promised=${this.promised}`;
      } else if (
        // has order number, and start, no end, no ac
        this.searchValue.length &&
        this.startDate != null &&
        this.endDate === null &&
        this.acFilter === null
      ) {
        return `${this.ordermanageapi}/order?search=${
          toUnicode(this.searchValue.trim())
        }&start=${this.formatDate(this.startDate)}&promised=${this.promised}`;
      } else if (
        // has order number, and end, no start, no ac
        this.searchValue.length &&
        this.startDate === null &&
        this.acFilter === null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?search=${
          toUnicode(this.searchValue.trim())
        }&end=${this.formatDate(this.endDate)}&promised=${this.promised}`;
      } else if (
        // has three, no ac
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter === null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?search=${
          toUnicode(this.searchValue.trim())
        }&start=${this.formatDate(this.startDate)}&end=${this.formatDate(
          this.endDate
        )}&promised=${this.promised}`;
      } else if (
        // has three, no end date
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate == null
      ) {
        return `${this.ordermanageapi}/order?search=${
          toUnicode(this.searchValue.trim())
        }&start=${this.formatDate(this.startDate)}&ac=${
          this.acFilter
        }&promised=${this.promised}`;
      } else if (
        // has three, no start date
        this.searchValue.length &&
        this.startDate == null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?search=${
          toUnicode(this.searchValue.trim())
        }&end=${this.formatDate(this.endDate)}&ac=${this.acFilter}&promised=${
          this.promised
        }`;
      } else if (
        // only has end
        !this.searchValue &&
        this.startDate === null &&
        this.endDate != null &&
        this.acFilter == null
      ) {
        return `${this.ordermanageapi}/order?end=${this.formatDate(
          this.endDate
        )}&promised=${this.promised}`;
      } else if (
        // only has start
        !this.searchValue &&
        this.startDate != null &&
        this.endDate === null &&
        this.acFilter == null
      ) {
        return `${this.ordermanageapi}/order?start=${this.formatDate(
          this.startDate
        )}&promised=${this.promised}`;
      } else if (
        // has start and end
        !this.searchValue &&
        this.startDate != null &&
        this.endDate != null &&
        this.acFilter == null
      ) {
        return `${this.ordermanageapi}/order?start=${this.formatDate(
          this.startDate
        )}&end=${this.formatDate(this.endDate)}&promised=${this.promised}`;
      } else if (
        //has all 4
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?ac=${this.acFilter}&search=${
          toUnicode(this.searchValue.trim())
        }&start=${this.formatDate(this.startDate)}&end=${this.formatDate(
          this.endDate
        )}&promised=${this.promised}`;
      } else if (
        //has only ac
        !this.searchValue &&
        this.startDate === null &&
        this.endDate === null &&
        this.acFilter != null
      ) {
        return `${this.ordermanageapi}/order?ac=${this.acFilter}&promised=${this.promised}`;
      } else if (
        // has ac and start
        !this.searchValue &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate === null
      ) {
        return `${this.ordermanageapi}/order?start=${this.formatDate(
          this.startDate
        )}&ac=${this.acFilter}&promised=${this.promised}`;
      } else if (
        // has ac and end
        !this.searchValue &&
        this.startDate === null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?end=${this.formatDate(
          this.endDate
        )}&ac=${this.acFilter}&promised=${this.promised}`;
      } else if (
        //has ac and search
        this.searchValue.length &&
        this.startDate === null &&
        this.acFilter != null &&
        this.endDate === null
      ) {
        return `${this.ordermanageapi}/order?search=${this.searchValue.trim()}&ac=${this.acFilter}&promised=${this.promised}`;
      } else if (
        //has ac and start and end
        !this.searchValue &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?ac=${
          this.acFilter
        }&start=${this.formatDate(this.startDate)}&end=${this.formatDate(
          this.endDate
        )}&promised=${this.promised}`;
      } else {
        return `${this.ordermanageapi}/order`;
      }
    },
  },
  created() {
    this.$store.commit("showNavigation");
  },

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.listing-bottom {
  align-items: flex-end;
}
.listing-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: scroll;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
  /*background-color: #fff;*/
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.date-area {
  margin-top: 15px;
}
.pag-wrapper {
  display: flex;
  width: 100%;
}
.pag-wrapper p {
  margin-bottom: 0;
}
.mrauto {
  margin-right: auto;
}
.search-btns {
  /*display: flex;*/
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 23px 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}

.search-btns .clear {
  margin-right: 0;
  margin-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.right-side {
  text-align: right;
  height: 57px;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
  .left-side {
    width: 400px;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
@media all and (min-width: 992px) {
  table {
    min-width: 100%;
  }
}

@media all and (max-width: 991px) {
  table {
    width: 800px !important;
    overflow: scroll;
  }
}
/*
flex-direction: column;
*/
.document-link {
  color: #429da8;
  cursor: pointer;
}
.document-link:hover {
  color: #00b4b4;
  cursor: pointer;
}
.document-link:active {
  color: #00b4b4;
  cursor: pointer;
}
/* row */
.new {
  background-color: rgba(0, 115, 219, 0.08) !important;
  /*background-color: rgba(77, 89, 103, 0.08) !important;*/
}
.radios {
  color: #fff;
}

/* radios */
.control-group {
  margin-top: 5px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.control {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
.control input:checked ~ .control__indicator {
  background: #4d5967;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #4d5967;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}
.new-search-btn1{
    background: #4d5967;
    color: #fff;
    border: none;
    transition: background-color 0.5s ease;
    cursor:pointer;
}
.new-search-btn1:hover{
    background:#373f49;
}
.new-search-btn2{
  background: #6a7b8e;
  color: #fff;
  border: none;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.new-search-btn2:hover{
  background: #3e4b5a;
}
</style>
